.container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 0px;
    padding: 20px 0px 20px 0px;
}

.tableWrapper {
    width: 90%;
    overflow-x: auto;
    background-color: #EEF3F7;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    padding: 12px;
    text-align: center;
    border: 1px solid black;
    height: 52px;
    box-sizing: border-box;
}

.table thead {
    background-color: #dbd1d1;
    color: black;
}

.table tbody tr:nth-child(even) {
    background-color: transparent;
}

.table tbody td {
    background-color: transparent;
    color: black;
}

.bodyType{
    font-family: "Nunito";
        color: #57647c;
        font-size: 16px;
        line-height: 28px;
        font-weight: 700;
        margin: 30px 0px 0px 0px;
}