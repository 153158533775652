.container {
  /* height: 880px; */
  width: 100%;
  position: top;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0,0,0,0.5); */
}

.backgroundImgWrapper {
  background-image: url("./../../../../assets/Solutions/img7-home5.png");
  width: 100%;
  min-height: 720px;
  top: 0;
  left: 0;
  z-index: -3121;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
}

.heading {
  color: #1e1666;
  font-family: "Nunito";
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  letter-spacing: 0.3px;
}

.contentSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.featuresCard {
  height: 280px;
  width: 320px;
  background-color: #fff;
  color: #080c2d;
  border-radius: 10px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 50px 0px rgb(19 51 98 / 7%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 5px;
}

.cardHeading {
  font-size: 18px;
  font-family: "Nunito";
  line-height: 28px;
  font-weight: bolder;
  font-style: normal;
  text-align: center;
  width: 180px;
}

.cardIcon {
  width: 80px;
  height: 80px;
}

.iconImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
    @media (min-width:768px) and (max-width: 1200px) {}
  
    @media (min-width:576px) and (max-width: 768px) {
      .contentSection{
        width: 90%;
      }
      .featuresCard{
        width: 100%;
        margin-bottom: 10px;
      }
    }
  
    @media only screen and (max-width: 576px) {
      .contentSection {
          width: 90%;
        }
      
        .featuresCard {
          width: 100%;
          margin-bottom: 10px;
        }
    }
