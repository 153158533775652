.container{
width: 100%;
height: 100%;
background-color: transparent;
background-image: linear-gradient(173deg, #0F054C 21%, #520C9C 100%);
display: flex;
justify-content: center;
align-items: center;
margin-top: 67px;
margin-bottom: 0px;
padding: 98px 0px 72px 0px;
}
.wrapper{
width: 90%;
height: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
.imageContainer{
    width: 48%;
    height: 100%;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.content{
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.heading{
    color: #FFFFFF;
    font-family: "Nunito";
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-top: 0px;
}
.body{
    color: #FFFFFF;
    font-size: 16px;
    line-height: 28px;
    font-family: "Nunito";
    font-weight: 400;
}

@media (min-width:768px) and (max-width: 1200px) {}

@media (min-width:576px) and (max-width: 768px) {
   
    .wrapper {
        width: 90%;
        flex-direction: column;
    }
    .imageContainer{
        width: 100%;
    }
    .content{
        margin-top: 20px;
        width: 100%;
    }

}

@media only screen and (max-width: 576px) {
    .wrapper {
            width: 90%;
            flex-direction: column;
        }
    
        .imageContainer {
            width: 100%;
        }
    
        .content {
            margin-top: 20px;
            width: 100%;
        }
}