.container{
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: #0F054C;
padding: 20px 0px;
margin: 20px 0px 0px 0px;
}
.wrapper{
width: 90%;
height: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
.content{
width: 48%;
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
}
.typeHeading{
font-family: 'Nunito';
color: #1BB7F1;
font-size: 12px;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 1.2px;
margin: 5px 0px;
}
.heading{
color: #FFFFFF;
font-size: 40px;
line-height: 48px;
font-weight: 800;
font-family: 'Nunito';
}
.body{
    color: #FFFFFFA1;
    line-height: 30px;
    font-weight: 700;
font-family: 'Nunito';
margin: 5px 0px;
}
.imageContainer{
width: 48%;
height: 100%;
}
.image{
width: 100%;
height: 100%;
object-fit: contain;
}

@media (min-width:768px) and (max-width: 1200px) {}

@media (min-width:576px) and (max-width: 768px) {
   .wrapper{
    width: 100%;
    flex-direction: column;
    padding: 0px 20px;
    box-sizing: border-box;
   }
   .content{
    width: 100%;
   }
   .imageContainer{
    width: 100%;
   }

}

@media only screen and (max-width: 576px) {
    .wrapper {
            width: 100%;
            flex-direction: column;
            padding: 0px 20px;
            box-sizing: border-box;
        }
    
        .content {
            width: 100%;
        }
    
        .imageContainer {
            width: 100%;
        }
}