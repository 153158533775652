.sohoContainer{
    padding-left: 0px;
}

@media (min-width:768px) and (max-width: 1200px) {}

 @media (min-width:576px) and (max-width: 768px) {
.sohoContainer {
        padding-left: 60px;
        box-sizing: border-box;
    }

 }

 @media only screen and (max-width: 576px) {}