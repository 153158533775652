.container{
    width: 100%;
    height: 500px;
    position: top;
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.overlay{
    width: 100%;
    height: 600px;
      background-color: rgba(0, 0, 0, 0.5); 
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
.backgroundImgWrapper{
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3121;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* .col1{
width: 100%;
margin-top: 100px;
} */

.content{
    width: 85%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

.headingType {
    z-index: 13;
    font-family: Nunito;
    height: auto;
    color: rgb(255, 255, 255);
    text-decoration: solid;
    white-space: normal; /* Allow text to wrap */
    /* width: 674px; */
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    line-height: 56px;
    letter-spacing: 0px;
    font-weight: 800;
    font-size: 48px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 0px;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
    @media (min-width:768px) and (max-width: 1200px) {}
  
    @media (min-width:576px) and (max-width: 768px) {}
  
    @media only screen and (max-width: 576px) {
        .content{
            width: 100%;
            padding: 0px 10px;
            box-sizing: border-box;
        }
        .headingType{
            width: 100%;
        }
    }

