.contact{
    width: 100%;
    height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
/* height: 100vh;
background-color: black;
position: fixed;
width: 100%;
top: 0;
z-index: -1; */
}
body{
    background-color: black;
}


@media only screen and (max-width: 576px){
    body{
        background-color: black;
    }
}
@media (min-width:576px) and (max-width: 768px) {}
@media (min-width:768px) and (max-width: 1200px) {}