.homeContainer {
  height: 100vh;
  /* background-color: black; */
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}
.navigationContainer{
  margin: 60px 0px 40px 0px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigationCard{
  width: 326px;
  height: 218px;
  cursor: pointer;
  margin-right: 30px;
}
.navigationImage{
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
    @media (min-width:768px) and (max-width: 1200px) {}
  
    @media (min-width:576px) and (max-width: 768px) {
      .homeContainer {
        /* padding-left: 60px; */
        box-sizing: border-box;
      }
      .navigationContainer{
        width: 100%;
        flex-direction: column;
        gap: 20px;
      }
      .navigationCard{
        width: 100%;
        height: 100%;
        margin: 0px;
      }
    }
  
    @media only screen and (max-width: 576px) {
      .homeContainer {
          /* padding-left: 60px; */
          box-sizing: border-box;
        }
      
        .navigationContainer {
          width: 100%;
          flex-direction: column;
          gap: 20px;
        }
      
        .navigationCard {
          width: 100%;
          height: 100%;
          margin: 0px;
        }
    }