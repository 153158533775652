.container{
width: 100%;
display: flex;
flex-direction: column;
margin: 40px 0px;
padding-top: 40px;
}
.content{
width: 100%;
display: flex;
align-items: flex-start;
justify-content: space-around;
padding: 0px 18px;
box-sizing: border-box;
}
.contentDetails{
    width: 32%;
display: flex;
flex-direction: column;
}
.typeHeading{
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: "Nunito";
    color: #1e1666;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-bottom: 20px;
}
.typeBody{
    margin: 0;
    font-family: "Nunito";
    color: #57647c;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 20px;

}
.contentImage{
width: 40%;
}
.card{
width: 100%;
display: flex;
align-items: flex-start;
margin-bottom: 20px;
}
.col1{
width: 10%;
margin-right: 40px;
}
.col2{
width: 90%;
display: flex;
flex-direction: column;

}
.cardHeading{
    font-family: 'Nunito';
    line-height: 28px;
    font-weight: bolder;
    font-style: normal;
    font-size: 18px;
    color: #1e1666;
    margin: 0px 0px 10px 0px;
}
.cardIcon{
    fill: #cc2b5e;
    color: #cc2b5e;
    font-size: 50px;
    padding: 0px;
    background-color: white;
    background-image: linear-gradient(to bottom,#ffffff 0%,#ffffff 100%,#753a88 100%), linear-gradient(to bottom,#753a88 0%,#cc2b5e 100%);
}
.cardBody{
color: #57647c;
margin: 0;
line-height: 30px;
}
.facts{
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
padding: 0px 18px;
margin-top: 20px;
box-sizing: border-box;
}

.factContent{
    width: 20%;
display: flex;
flex-direction: column;
}
.factHeading{
    position: relative;
    color: #03B5A9;
    font-size: 56px;
    line-height: 50px;
    font-weight: bolder;
}
.factIcon{
position: absolute;
top: -8px;
left: 20;
color: #03B5A9;
font-size: 26px;
}
.factStatus{
    font-family: "Nunito";
    color: #1e1666;
    font-size: 18px;
    line-height: 48px;
    font-weight: 800;
}
        @media (min-width:768px) and (max-width: 1200px) {}
    
        @media (min-width:576px) and (max-width: 768px) {
          .content{
            flex-direction: column-reverse;
            align-items: center;
          }
          .contentDetails{
            width: 100%;
          }
          .contentImage{
            width: 90%;
          }
          .typeHeading{
            text-align: center;
            margin-top: 20px;
          }
          .card{
            flex-direction: column;
          }
          .facts{
            max-width: 100%;
            flex-wrap: wrap;
          }
          .factContent{
                width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
          }
          .factStatus{
            text-align: center;
          }
        }
    
        @media only screen and (max-width: 576px) {
          .content {
              flex-direction: column-reverse;
              align-items: center;
            }
          
            .contentDetails {
              width: 100%;
            }
          
            .contentImage {
              width: 90%;
            }
          
            .typeHeading {
              text-align: center;
              margin-top: 20px;
            }
          
            .card {
              flex-direction: column;
            }
          
            .facts {
              max-width: 100%;
              flex-wrap: wrap;
            }
          
            .factContent {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          
            .factStatus {
              text-align: center;
            }
        }