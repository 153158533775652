.container{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 0px;
margin: 20px 0px;
}
.wrapper{
width: 90%;
height: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}
.content{
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
}
.heading{
    font-size: 40px;
    line-height: 48px;
    color: #1e1666; 
    font-family: "Nunito";
    font-weight: 800;
    margin: 10px 0px;
}
.body{
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito";
    font-weight: 700;
        margin: 10px 0px;
}
.imageContainer{
width: 50%;
height: 500px;
}
.image{
width: 100%;
height: 100%;
object-fit: contain;
}

@media (min-width:768px) and (max-width: 1200px) {}

@media (min-width:576px) and (max-width: 768px) {
    .wrapper {
        /* width: 100%; */
        flex-direction: column;
    }
    .content{
        width: 100%;
    }
    .imageContainer{
        width: 90%;
    }

}

@media only screen and (max-width: 576px) {
    .wrapper {
            /* width: 100%; */
            flex-direction: column;
        }
    
        .content {
            width: 100%;
        }
    
        .imageContainer {
            width: 90%;
        }
}