.scrollButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Use rgba with alpha value to increase visibility */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.showButton {
  opacity: 1;
}

.arrow {
  display: inline-block;
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(-135deg);
}
