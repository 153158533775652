.card {
    width: 100%;
    height: 200px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 35px 30px 32px 32px;
    margin-bottom: 20px;
    box-sizing: border-box;
    box-shadow: 2.5px 4.33px 18px 0px rgba(40, 134.00000000000014, 172, 0.08);
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 91px;
  }
  
  .logo {
    width: 100%;
    height: 100%;
  }
  
  .cardContent {
    margin-left: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0px;
  }
  
  .heading {
    color: #1e1666;
    font-size: 20px;
    line-height: 28px;
    font-family: "Nunito";
    font-weight: 800;
    margin: 0;
  }
  
  .content {
    font-size: 15px;
    line-height: 28px;
    font-family: "Nunito";
    color: #57647c;
    font-weight: 700;
  }
 @media only screen and (max-width: 576px) {
   .card {
     height: 100%;
     flex-direction: column;
     align-items: center;
   }

         .cardContent {
           width: 100%;
           text-align: center;
           display: flex;
           flex-direction: column;
           align-items: flex-start;
           justify-content: center;
         }
         }
    
         @media (min-width:576px) and (max-width: 768px) {
           .card {
             height: 100%;
             flex-direction: column;
             align-items: center;
           }
         }
    
         @media (min-width:768px) and (max-width: 1024px) {}