.partnershipContainer {
  width: 100%;
  height: 100%;
  }
    @media (min-width:768px) and (max-width: 1200px) {}
  
    @media (min-width:576px) and (max-width: 768px) {
  .partnershipContainer{
    padding-left: 60px;
    box-sizing: border-box;
  }
    }
  
    @media only screen and (max-width: 576px) {
    
    }