.container {
    width: 100%;
    height: 490px;
    position: top;
    display: flex;
    justify-content: center;
}
.overlay{
  width: 100%;
  height: 600px;
    background-color: rgba(0, 0, 0, 0.6); 
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
.backgroundImgWrapper {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3121;
}

.img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
    position: relative;
}

.col1 {
    width: 80%;
    margin-top: 140px;
}

.content {
    margin-top: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}


.headingType {
    font-family: Nunito;
    color: rgb(255, 255, 255);
    font-weight: 900; /* Updated to a higher font weight */
    font-size: 48px;
    text-align: center;
    margin-top: -12%;
    margin-bottom: 3%;
    width: 100%;
}


.bodyType {
    font-family: Nunito;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    width: 100%;
};
@media (min-width:768px) and (max-width: 1200px) {}
@media (min-width:576px) and (max-width: 768px) {
    .col1 {
            width: 100%;
        }
    
        .headingType {
            width: 100%;
        }
    
        .bodyType {
            width: 100%;
        }
        .container{
            height: 100%;
            padding: 20px;
                box-sizing: border-box;
        }
}
@media only screen and (max-width: 576px) {
    .col1 {
        width: 100%;
    }

    .headingType {
        width: 100%;
    }

    .bodyType {
        width: 100%;
    }
         .container {
             height: 100%;
             padding: 20px;
             box-sizing: border-box;
         }
}
