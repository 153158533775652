.langSwitchButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Arial, sans-serif;
  color: #000;
}

.checkbox {
  display: none;
}

.label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  background-color: #fffdfd;
  border-radius: 25px;
  transition: background-color 0.3s;
}

.toggle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: #4d4492;
  border-radius: 50%;
  transition: transform 0.3s;
}

.checkbox:checked + .label {
  background-color: #4d4492;
}

.checkbox:checked + .label .toggle {
  transform: translateX(25px);
}

.language {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.language[data-language='pt']::after {
  content: 'PT';
}

.language[data-language='en']::after {
  content: 'EN';

}
