.container{
    max-width: 100%;
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: center;
    /* margin-top: 20px; */
    background-color: rgba(0,0,0,0.5);
}
.backgroundGifWrapper{
    max-width: 100%;
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3121;
}
.gif{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.col1{
width: 45%;
margin-top: 100px;

}
.roboWall{
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.redLightOverlay{
    position: absolute;
    top: -60px;
    right: 0;
    z-index: 1;
    width: 484px;
    height: 349px;
}
.redLightImage{
width: 100%;
height: 100%;
object-fit: cover;
object-position: top right;
}
.headingType{
    z-index: 13;
    font-family: Nunito;
    height: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    width: 674px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: left;
    line-height: 56px;
    letter-spacing: 0px;
    font-weight: 800;
    font-size: 48px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
}
.bodyType{
    z-index: 14;
    font-family: Nunito;
    height: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    width: 571px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: left;
    line-height: 28px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 18px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
}
.col2{
width: 40%;
position: relative;

}
.robotOverlay{
width: 513px;
height: 490px;
position: absolute;
right: -90px;
top: -100px;


}
.robotImage{
width: 100%;
height: 100%;
object-fit: cover;
}
        @media (min-width:768px) and (max-width: 1200px) {}
    
        @media (min-width:576px) and (max-width: 768px) {
           .container{
            width: 100%;
            height: 100%;
            flex-direction: column;
           }
           .headingType{
            width: 100%;
           }
           .bodyType{
            display: none;
           }
           .redLightOverlay{
            display: none;
           }
           .roboWall{
            display: none;
           }
           .col1{
            margin-top: 0px;
            width: 100%;
            padding-bottom: 40px;
            box-sizing: border-box;
           }
           .content{
            width: 80%;
           }
           .col2{
            width: 100%;
            display: flex;
            justify-content: flex-end;
           }
           .robotOverlay{
            width: 277px;
            height: 377px;
            position: absolute;
            right: 0;
            top: -180px;
           }
           .robotImage{
            object-fit: cover;
            object-position: top left;           
    }
           
        }
    
        @media only screen and (max-width: 576px) {
            .container {
                    width: 100%;
                    height: 100%;
                    flex-direction: column;
                }
            
                .headingType {
                    width: 100%;
                }
            
                .bodyType {
                    display: none;
                }
            
                .redLightOverlay {
                    display: none;
                }
            
                .roboWall {
                    display: none;
                }
            
                .col1 {
                    margin-top: 0px;
                    width: 100%;
                    padding-bottom: 40px;
                    box-sizing: border-box;
                }
            
                .content {
                    width: 80%;
                }
            
                .col2 {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }
            
                .robotOverlay {
                    width: 277px;
                    height: 377px;
                    position: absolute;
                    right: 0;
                    top: -238px;
                }
            
                .robotImage {
                    object-fit: cover;
                    object-position: top left;
                }
        }