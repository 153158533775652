.offerContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px 80px 0px;
}
.offerContent{
padding: 0px 40px;
width: 100%;
display: flex;
flex-direction: column;

}
.firstRow{
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
margin-bottom: 10px;
margin-top: 80px;
}
.typeHeading{
    margin: 0;
    /* width: 40%; */
    text-align: left;
    font-family: "Nunito";
    color: #1e1666;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
}
.typeBody{
    margin: 0;
    width: 30%;
    font-family: "Nunito";
    color: #57647c;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;

}
.secondRow{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin-top: 40px;
gap: 20px;
}

  @media (min-width:768px) and (max-width: 1200px) {}

  @media (min-width:576px) and (max-width: 768px) {
    .offerContent{
        padding: 0px;
    }
     .firstRow{
        flex-direction: column;
                text-align: left;
     }
     .typeHeading{
        text-align: left;
        width: 100%;
     }
     .typeBody{
        width: 100%;
        margin-top: 40px;
     }
     .secondRow{
        padding: 0px 20px;
        box-sizing: border-box;
     }
  }

  @media only screen and (max-width: 576px) {
    .offerContent {
            padding: 0px;
        }
    
        .firstRow {
            flex-direction: column;
            text-align: left;
        }
    
        .typeHeading {
            text-align: left;
            width: 100%;
        }
    
        .typeBody {
            width: 100%;
            margin-top: 40px;
        }
    
        .secondRow {
            padding: 0px 20px;
            box-sizing: border-box;
        }
  }