
.container{
width: 100%;
height: 100%;
background-color: transparent;
background-image: linear-gradient(173deg, #0F054C 21%, #520C9C 100%);
display: flex;
justify-content: center;
align-items: center;    
margin-top: 67px;
margin-bottom: 0px;
padding: 98px 0px 72px 0px;
}

.tableWrapper {
    width: 80%;
    overflow-x: auto;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    padding: 12px;
    text-align: center;
    border: 1px solid white;
    height: 52px;
    box-sizing: border-box;
}

.table thead {
    background-color: #000000;
    color: #fff;
}

.table tbody tr:nth-child(even) {
    background-color: transparent;
}

.table tbody td {
    background-color: transparent;
    color: white;
}