
.headingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EEF3F7;
}
.wrapper{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info {
    /* text-align: center; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  
  .heading {
    color: #1E1666;
    font-family: "Nunito";
    font-size: 40px;
    line-height: 30px;
    font-weight: bolder;
    margin: 0px;
  }
  
  .content {
    color: #1E1666;
    font-family: "Nunito";
    line-height: 28px;
    font-weight: 800;
    font-style: normal;
    font-size: 20px;
    margin-top: 10px;

  }

.container {
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 80px;
  }
  
  .cardsContainer {
    width: 60%;
    /* margin-left: 80px; */

  }
  
  .imageContainer {
    width: 40%;
    height: 590px;
    margin-top: 100px;
  }
  
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
@media only screen and (max-width: 576px) {
  .wrapper {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }

  .cardsContainer {
    width: 90%;
  }

  .imageContainer {
    width: 90%;
  }
}

@media (min-width:576px) and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }

  .cardsContainer {
    width: 90%;
  }
}

@media (min-width:768px) and (max-width: 1024px) {
  .wrapper {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }

  .cardsContainer {
    width: 90%;
  }
}