.container{
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
   width: 80%;
   height: 100%; 
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px 0px;
}
.content h1{
    color: #1e1666;
    font-size: 40px;
    line-height: 48px;
}
.content p{
    color: #57647c;
    font-size: 18px;
    line-height: 30px;
}
.addressContainer{
width: 90%;
display: flex;
justify-content: space-around;
align-items: center;
}
.card{
width: 306px;
height: 182px;
display: flex;
flex-direction: column;
align-items: center;
}
.iconContainer{
width: 80px;
height: 80px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-size: 200%;
background-image: linear-gradient(to right,#cc2b5e 0%,#753a88 51%,#cc2b5e 100%);
/* display: inline-block; */
position: relative;
box-sizing: border-box;
transition: background-position 0.4s ease, box-shadow 0.4s ease, transform 0.5s ease;
cursor: pointer;
}
.iconContainer:hover{
    background-position: right;
}
.card h1{
    font-size: 18px;
    font-family: 'Nunito';
    line-height: 28px;
    font-weight: 800;
    font-style: normal;
}
.card address{
/* color: #57647c; */
color: rgb(87,75,147);
font-family: Nunito;
line-height: 28px;
font-weight: 800;
font-style: normal;
font-size: 16px;
}

.companyEmail{
    size: 8px;
    cursor: pointer;
    width: 220px;
    height: 40px;
    object-fit: contain;
    margin-top: -8px;
  }

@media only screen and (max-width: 576px) {
    .addressContainer {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width:576px) and (max-width: 768px) {
    .addressContainer {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width:768px) and (max-width: 1200px) {}