.container{
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 20px 0px;
margin: 20px 0px;
}
.wrapper{
width: 80%;
height: 100%;
display: flex;
align-items: center;
justify-content: space-between;
background-color: #EEF3F7;
padding: 90px 100px 106px 91px;
border-radius: 5px;
}
.imageContainer{
width: 50%;
position: relative;
}
.macContainer{
    width:100%;
    height:100%;
}
.mac{
   width:100%;
   height: 100%;
   object-fit: contain;
}
.iphone3{
    position: absolute;
    top: 0;
    right: 90px;
}
.content{
width: 50%;
display: flex;
flex-direction: column;
align-items: flex-start;
}
.heading{
    color: #1e1666;
    font-family: "Nunito";
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin: 10px 0px;
}
.body{
    color: #57647c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Nunito";
     font-weight: 800;
    margin: 10px 0px;
}

@media (min-width:768px) and (max-width: 1200px) {}

@media (min-width:576px) and (max-width: 768px) {
   .wrapper{
    width: 100%;
    flex-direction: column;
   }
   .imageContainer{
    width: 100%;
   }
   .content{
    width: 100%;
    margin-top: 20px;
   }

}

@media only screen and (max-width: 576px) {
    .wrapper {
            width: 100%;
            flex-direction: column;
            padding: 20px;
        }
    
        .imageContainer {
            width: 100%;
        }
    
        .content {
            width: 100%;
            margin-top: 20px;
        }
        .heading{
            text-align: center;
        }
}