.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
    background-color: black;
    color: white;
    padding: 20px;
    /* height: 474px; */
    box-sizing: border-box;
    /* margin-top: 20px; */
    margin-bottom: 0;
  }
  
  .footerContent {
    width: 90%;
    margin-top: 40px;
    padding-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 20px;
  }
  .footerLeft{
    width: 55%;
  }
  .footerLeft p {
    margin-bottom: 10px;
  }
.linkHeading{
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}
  .footerLeft a {
    color: white;
    text-decoration: none;
  }
  .headingType{
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    margin: 0;
  }
  
  .footerLogo {
    height: 120px;
    vertical-align: middle;
  }

  .footerRight{
        text-align: left;
  }
  
  .footerRight p {
    margin-bottom: 20px;
  }
  
  .footerRight ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .footerRight li {
    margin-bottom: 10px;
  }
  
  .footerRight a {
    color: #FFFFFF99;
    text-decoration: none;
    font-size: 12px;
  }

  .footerRight a:hover {
    color: #29A9D8;
    border-bottom: 1px solid #29A9D8;
    transition: 0.2s all ease-in-out;
  }

  .footerBottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .copyRight{
    color: #CCCCFF;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.3px;
    width: 26%;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  .footerBottomContent{
    margin-bottom: 20px;
    margin-top: -50px;
    margin-left: 20px;
  }

            @media (max-width: 576px) {
              .footerContent {
                  width: 100%;
                  /* margin-top: 40px; */
                  /* padding-top: 40px; */
                  /* display: flex; */
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  margin-bottom: 20px;
                }
                .linkHeading{
                  color: #FFFFFF;
                    font-size: 22px;
                    line-height: 36px;
                  }
                  .footerLeft{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .footerBottom {
                  width: 100%;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
                .copyRight{
                  width: 100%;
                }
            }
            @media (max-width: 768px) {
              .footerContent {
                  width: 100%;
                  /* margin-top: 40px; */
                  /* padding-top: 40px; */
                  /* display: flex; */
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  margin-bottom: 20px;
                }
              
                .linkHeading {
                  color: #FFFFFF;
                  font-size: 22px;
                  line-height: 36px;
                }
              
                .footerLeft {
                  width: 100%;
                  margin-bottom: 20px;
                }
              
                .footerBottom {
                  width: 100%;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
              
                .copyRight {
                  width: 100%;
                  text-align: center;
                }
            }